/*
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-02-17 13:57:36
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-27 13:03:02
 * @FilePath: /site/src/config/index.js
 */

const config = {
  title: "Video",
  title_en: "Video System",
  meta: [
    {
      // set meta
      name: "keyWords",
      content: "address",
    },
  ],
  serverURL: "http://localhost:7002", //后端服务器地址,
  serverPublicURL: "/", //后端服务器地址,
  pageSize: 15, //pc 端每页显示信息条数,
  uploadImgMaxSize: {
    size: 1048576 * 5, //上传图片大小限制
    sizeName: "5m",
  },
};

if (process.env.NODE_ENV === "product") {
  config.serverURL = "https://xy.yltnf.com";
}

module.exports = config;
