<template>
  <ul>
    <li v-if="page > 1" @click="page = 1">1</li>
    <li
      v-for="i in pageItemCount"
      :key="'pageli' + i"
      @click="page = pageComputed(i)"
      :class="{ active: page == i }"
    >
      {{ pageComputed(i) }}
    </li>
    <li v-if="page < pages - pagerCount" @click="page = pages">{{ pages }}</li>
  </ul>
</template>

<script>
import "./style.scss";

export default {
  name: "ImCarousel",
  props: {
    pageSize: {
      type: [Number, String],
      default: () => 20,
    },
    value: {
      type: [Number, String],
      default: () => 1,
    },
    total: {
      type: [Number, String],
      default: () => 0,
    },
    pagerCount: {
      type: [Number, String],
      default: () => 5,
    },
  },

  data() {
    return {
      pages: 0,
      page: 1,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.page = val;
      },
      deep: true,
    },
  },
  mounted() {
    this.page = this.value;
    if (this.total > 0) {
      this.pages = Math.ceil(this.total / this.pageSize);
    }
  },
  computed: {
    pageItemCount() {
      let num = this.pagerCount;
      if (this.pages < this.pagerCount) {
        num = this.pages;
      }
      return num;
    },
  },
  methods: {
    pageComputed(i) {
      console.log(this.page);
      let num = i;
      if (this.page > 1 && this.page < this.pages - this.pagerCount) {
        return this.page + i;
      }

      if (this.pages - this.page < this.pagerCount) {
        return this.page - i;
      }

      return num;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 20px;
    & + li {
      margin-left: 20px;
    }
    &.active {
      background: #db0000;
      colir: #fff;
    }
  }
}
</style>