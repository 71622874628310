/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-08-03 06:47:58
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-27 07:52:16
 * @FilePath: /site/src/mixins/index.js
 */
export default {
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {
    viewImg(img) {
      if (this.$utils.isURL(img)) {
        return img;
      }
      return this.$config.serverPublicURL + img;
    },
    viewThumb(thumb, size = "small") {
      if (!thumb) {
        return "";
      }
      const img = thumb[size];
      return this.viewImg(img);
    },

    //顶踩比
    favVsUnfav(item) {
      if (!item.fav && !item.unfav) {
        return "0:0";
      }
      if (!item.fav && item.unfav) {
        return "0:" + item.unfav;
      }
      if (item.fav && !item.unfav) {
        return item.fav + ":0";
      }

      return "1:" + Math.floor(item.unfav / item.fav);
    },
    //大赛状态
    raceStatus(item) {
      if (item.is_close > 0) {
        return "已关闭";
      }
      const time = this.$utils.time();
      if (item.start_time > time && time < item.end_time) {
        return "进行中";
      }
      if (time > item.end_time) {
        return "已结束";
      }
    },
  },
};
