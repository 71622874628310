/*
 * @Description:
 * @CopyRight: Tapp Commerce
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-04-11 09:42:03
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-09-13 23:19:38
 * @FilePath: /site/src/components/pagination/index.js
 */
import Pagination from "./src/Pagination.vue";
Pagination.install = function (Vue) {
  Vue.component("Pagination", Pagination);
};
export default Pagination;

export { Pagination };
