<template>
  <router-link
    target="_blank"
    class="nickname"
    v-if="currentUser && currentUser.nickname"
    :to="'/vlog/' + currentUser.id"
  >
    <template v-if="currentUser.org">
      <el-tooltip :content="currentUser.org.name" placement="top" effect="dark">
        <span class="nickname">
          <template v-if="showAvatar">
            <el-avatar
              :size="avatarSize == 'mini' ? 'small' : avatarSize"
              :src="
                currentUser.avatar
                  ? viewImg(currentUser.avatar)
                  : require('../assets/images/userAvatar.png')
              "
              class="mr-10"
              :class="'avatar-size-' + avatarSize"
            ></el-avatar>
          </template>

          {{ currentUser.nickname }}
          <span v-if="currentUser.is_v > 0" class="is-v">V</span>
        </span>
      </el-tooltip>
    </template>
    <template v-else>
      <template v-if="showAvatar">
        <el-avatar
          :size="avatarSize == 'mini' ? 'small' : avatarSize"
          :src="
            currentUser.avatar
              ? viewImg(currentUser.avatar)
              : require('../assets/images/userAvatar.png')
          "
          class="mr-10"
          :class="'avatar-size-' + avatarSize"
        ></el-avatar>
      </template>
      {{ currentUser.nickname }}
      <span v-if="currentUser.is_v > 0" class="is-v">V</span>
    </template>
  </router-link>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    avatarSize: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      currentUser: null,
    };
  },
  watch: {
    value: {
      handler: function (value) {
        this.currentUser = value;
      },
      deep: true,
    },
  },
  mounted() {
    this.currentUser = this.value;
  },
};
</script>

<style lang="scss" scoped>
.is-v {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  background: #ffd500;
  color: #fff;
  font-size: 11px;
  text-align: center;
  border-radius: 50%;
}
.nickname {
  display: inline-flex;
  align-items: center;
}
.avatar-size-mini {
  width: 24px;
  height: 24px;
}
</style>