/*
 * @Descripttion: []
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-05-22 00:51:13
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-10-17 19:24:12
 * @FilePath: /site/src/plugins/Axios.js
 */

import axios from "axios";

let defaultConfig = {
    timeout: 1200000, // Timeout
    withCredentials: true, // Check cross-site Access-Control
    headers: {
        "Content-Type": "application/json",
    },
};
let cancelToken = axios.CancelToken.source(); // 创建终止请求实例
const _Axios = (options) => {
    const _option = Object.assign({}, defaultConfig, options);

    const error = (msg) => {
        if (_option.error && typeof _option.error == "function") {
            return _option.error(msg, () => {
                //终止所有请求
                cancelToken.cancel("Operation canceled by the user");
                //重新签发令牌 ，否则无法再次发出请求
                cancelToken = axios.CancelToken.source();
            });
        }
    };

    const _axios = axios.create(_option);

    _axios.interceptors.request.use(
        (config) => {
            // 全局添加cancelToken 用于终止所有请求
            config.cancelToken = cancelToken.token;
            //根据token名称取得 localStorage 中储存的token
            if (_option.getToken) {
                config.headers.Authorization = _option.getToken();
            }
            return config;
        },
        (err) => {
            error(err);
            // Do something with request error
            return Promise.reject(err);
        }
    );

    // Add a response interceptor
    _axios.interceptors.response.use(
        (response) => {
            // Do something with response data
            if (response.data.token && _option.updateAuth) {
                _option.updateAuth(response.data);
            }
            if (response.msg || (response.data && response.data.msg)) {
                error(response.msg || response.data.msg || "通讯错误");

                return Promise.reject(response);
            }
            if (_option.tokenName && response.data.token) {
                //用户token 续存
                localStorage.setItem(_option.tokenName, response.data.token);
            }

            if (typeof _option.success == "function") {
                _option.success(response);
            }

            return response.data;
        },
        (err) => {
            let resErr = null;
            if (err && err.response) {
                if (err.response.status !== 200) {
                    if (err.response.status == 401 || err.response.status == 403) {
                        resErr = err.response;
                    } else {
                        resErr = "通讯错误：" + err.response.status;
                    }
                }
            } else {
                resErr = "连接到服务器失败";
            }

            if (axios.isCancel(err)) {
                return new Promise(() => {});
            } else {
                error(resErr);
                // Do something with response err
                return Promise.reject(err);
            }
        }
    );

    return _axios;
};

export default _Axios;