/*
 * @Description:
 * @CopyRight: Tapp Commerce
 * @Author: Iseven Monkey <iswoter@gmail.com>
 * @Date: 2021-04-11 23:29:43
 * @LastEditors: Iseven Monkey <iswoter@gmail.com>
 * @LastEditTime: 2021-04-11 23:30:03
 * @FilePath: /src/components/tree/bus.js
 */
import Vue from "vue";
const bus = new Vue();
export default bus;
