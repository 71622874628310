<template>
  <div>
    <svg
      v-if="type == 'dot'"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="24199"
      :width="size"
    >
      <path
        d="M692.071703 329.86173c-48.372707-48.387034-112.705921-75.023694-181.146687-75.023694-68.440765 0-132.747373 26.63666-181.148733 75.023694-48.400337 48.374754-75.022671 112.707968-75.022671 181.123151s26.623357 132.74635 75.022671 181.146687c48.374754 48.402383 112.707968 75.023694 181.148733 75.023694 68.441788 0 132.745327-26.647917 181.146687-75.023694 48.427966-48.373731 75.024717-112.706945 75.024717-181.146687C767.096421 442.544115 740.447481 378.237507 692.071703 329.86173zM510.923994 696.667883c-102.363366 0-185.65742-83.273589-185.65742-185.658444 0-102.373599 83.293031-185.656397 185.65742-185.656397 102.384855 0 185.658444 83.283822 185.658444 185.656397C696.582437 613.394295 613.308849 696.667883 510.923994 696.667883z"
        p-id="24200"
        :fill="color"
      ></path>
    </svg>
    <svg
      v-if="type == 'share'"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
    >
      <path
        d="M752 664c-28.5 0-54.8 10-75.4 26.7L469.4 540.8c1.7-9.3 2.6-19 2.6-28.8s-0.9-19.4-2.6-28.8l207.2-149.9C697.2 350 723.5 360 752 360c66.2 0 120-53.8 120-120s-53.8-120-120-120-120 53.8-120 120c0 11.6 1.6 22.7 4.7 33.3L439.9 415.8C410.7 377.1 364.3 352 312 352c-88.4 0-160 71.6-160 160s71.6 160 160 160c52.3 0 98.7-25.1 127.9-63.8l196.8 142.5c-3.1 10.6-4.7 21.8-4.7 33.3 0 66.2 53.8 120 120 120s120-53.8 120-120-53.8-120-120-120z m0-476c28.7 0 52 23.3 52 52s-23.3 52-52 52-52-23.3-52-52 23.3-52 52-52zM312 600c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88z m440 236c-28.7 0-52-23.3-52-52s23.3-52 52-52 52 23.3 52 52-23.3 52-52 52z"
        :fill="color"
      ></path>
    </svg>

    <svg
      v-if="type == 'like'"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4407"
      :width="size"
    >
      <path
        d="M595.393939 81.842424c-46.545455 0-87.143434 31.806061-95.676767 76.8 0 0-13.834343 70.464646-21.074748 95.806061-11.894949 41.761616-53.527273 87.919192-83.652525 102.658586-22.755556 11.248485-107.313131 11.377778-112.484848 11.377777h-115.070708C135.757576 368.484848 110.028283 394.084848 110.028283 425.890909v459.248485c0 31.676768 25.6 57.406061 57.40606 57.406061h529.842425c85.20404 0 157.608081-62.319192 170.278788-146.618182L911.90303 500.363636c10.472727-69.430303-43.313131-131.749495-113.519192-131.749495h-130.585858s19.393939-64.258586 29.220202-172.735353c4.913131-54.949495-34.00404-107.313131-91.151515-113.260606-3.490909-0.517172-6.981818-0.775758-10.472728-0.775758zM339.523232 885.010101V424.080808c37.624242-2.456566 64.775758-7.757576 80.808081-15.773737 42.278788-20.945455 96.323232-78.351515 113.389899-138.343435 7.628283-26.763636 20.686869-92.961616 22.238384-100.719192 3.361616-17.325253 19.911111-30.125253 39.30505-30.125252 1.551515 0 3.10303 0.129293 4.654546 0.258586 27.280808 2.844444 41.890909 28.70303 39.951515 50.941414-9.050505 100.460606-26.892929 160.969697-27.151515 161.357576l-22.367677 73.955555h208.032323c16.937374 0 32.452525 7.111111 43.571718 19.911111 11.119192 12.8 15.773737 29.220202 13.187878 46.028283l-44.218181 295.563637c-8.533333 56.630303-56.242424 97.745455-113.519192 97.745454H339.523232z m-172.088889 0V425.761616h114.682829v459.248485H167.434343z m0 0"
        :fill="color"
        p-id="4408"
      ></path>
    </svg>
    <svg
      v-if="type == 'unlike'"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="23372"
      :width="size"
    >
      <path
        d="M149.632 618.858667a21.333333 21.333333 0 0 1-21.354667-21.269334L128.085333 191.744a21.333333 21.333333 0 0 1 21.376-21.269333h549.056a106.752 106.752 0 0 1 102.464 76.330666l93.184 317.504a42.624 42.624 0 0 1-40.981333 54.549334H705.493333c-6.826667 0-13.632 0.810667-20.266666 2.432a85.056 85.056 0 0 0-62.762667 102.805333l22.272 88.746667a81.514667 81.514667 0 0 1-46.293333 93.866666c-14.976 7.04-23.488 10.538667-25.557334 10.538667-11.797333 0-22.613333-6.101333-24.426666-17.706667-28.544-180.330667-103.530667-280.682667-230.357334-280.682666H149.632zM318.122667 682.666667c87.253333 0 142.805333 73.685333 167.04 226.816C492.501333 955.904 528.768 981.333333 576 981.333333c7.104 0 23.296-5.44 48.554667-16.32a145.258667 145.258667 0 0 0 82.453333-167.317333l-22.250667-88.725333a21.269333 21.269333 0 0 1 15.701334-25.706667c1.088-0.256 52.010667-0.469333 152.725333-0.597333C912.170667 682.666667 960 635.050667 960 576.32c0-10.154667-1.472-20.266667-4.352-30.016l-93.184-317.525333A170.816 170.816 0 0 0 698.517333 106.666667H149.461333C102.250667 106.666667 64 144.768 64 191.744l0.170667 405.845333C64.170667 644.565333 102.442667 682.666667 149.632 682.666667h168.490667z m-83.2-63.808h64.085333L298.666667 170.474667h-64.085334l0.341334 448.384z"
        :fill="color"
        p-id="23373"
      ></path>
    </svg>
    <svg
      v-if="type == 'star'"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="25661"
      :width="size"
    >
      <path
        d="M934.4 356.266667c-8.533333-10.666667-21.333333-19.2-34.133333-21.333334l-234.666667-34.133333-104.533333-213.333333c-6.4-8.533333-14.933333-17.066667-25.6-23.466667-12.8-6.4-27.733333-6.4-40.533334-2.133333-12.8 4.266667-23.466667 14.933333-29.866666 27.733333l-104.533334 213.333333-234.666666 34.133334c-10.666667 2.133333-21.333333 6.4-29.866667 14.933333-21.333333 21.333333-19.2 55.466667 0 74.666667l170.666667 166.4-40.533334 234.666666c-2.133333 10.666667 0 23.466667 6.4 34.133334 12.8 25.6 46.933333 36.266667 72.533334 21.333333l211.2-110.933333 211.2 110.933333c8.533333 4.266667 17.066667 6.4 25.6 6.4h8.533333c14.933333-2.133333 25.6-10.666667 34.133333-21.333333 8.533333-10.666667 10.666667-25.6 8.533334-40.533334l-40.533334-234.666666 170.666667-166.4c8.533333-8.533333 14.933333-19.2 14.933333-29.866667-2.133333-14.933333-6.4-27.733333-14.933333-40.533333z m-224 194.133333c-12.8 12.8-19.2 29.866667-14.933333 46.933333l38.4 217.6L512 699.733333l-221.866667 115.2L328.533333 597.333333c2.133333-17.066667-2.133333-34.133333-14.933333-46.933333l-157.866667-153.6 219.733334-32c17.066667-2.133333 32-12.8 40.533333-29.866667L512 136.533333l98.133333 198.4c8.533333 14.933333 23.466667 27.733333 40.533334 29.866667l219.733333 32-160 153.6z"
        p-id="25662"
        :fill="color"
      ></path>
    </svg>

    <svg
      v-if="type == 'comment'"
      t="1636283536785"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2484"
      :width="size"
    >
      <path
        d="M481.578667 968.832l-157.909334-158.293333H85.418667A85.290667 85.290667 0 0 1 0 725.12L0.426667 128.042667C0.426667 80.768 38.4 42.666667 85.845333 42.666667h852.778667c47.189333 0 85.418667 38.101333 85.376 85.418666l-0.426667 597.12c0 47.146667-38.229333 85.333333-85.376 85.333334h-237.781333l-158.549333 158.293333a42.624 42.624 0 0 1-60.288 0z m456.704-243.712L938.666667 128.042667s-852.906667-0.128-852.906667 0.042666c0 0-0.384 597.077333-0.298667 597.077334H341.333333c11.306667 0 22.186667 4.48 30.165334 12.501333l140.330666 140.629333 140.8-140.672a42.624 42.624 0 0 1 30.165334-12.458666h255.488zM277.333333 512a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m234.666667 0a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m234.666667 0a64 64 0 1 1 0-128 64 64 0 0 1 0 128z"
        :fill="color"
        p-id="2485"
      ></path>
    </svg>

    <svg
      v-if="type == 'home'"
      t="1636284579907"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3305"
      :width="size"
    >
      <path
        d="M267.906 537.159v301.379c0.187 11.409 7.559 18.642 13.542 22.132 6.307 3.606 13.17 5.183 20.406 5.183l136.62 0.047c4.684 0 9.322-1.995 12.614-5.228 3.385-3.352 5.241-7.988 5.241-12.661v-145.027l136.11 0.047v144.979c0 4.672 1.9 9.31 5.24 12.661 3.34 3.234 7.929 5.182 12.66 5.182h136.528c7.327 0 14.145-1.577 20.451-5.182 6.028-3.49 13.357-10.724 13.588-22.132v-301.379l-256.546-234.656-256.453 234.656zM761.242 344.795v-170.15c0-10.296-8.394-18.712-18.782-18.712h-45.171c-10.342 0-18.642 8.417-18.642 18.712v94.606l-121.826-111.415c-18.411-16.857-46.467-16.857-64.878 0l-323.788 296.254c-19.569 17.842-20.916 48.276-3.015 67.892 9.46 10.342 22.446 15.629 35.476 15.629 11.64 0 23.234-4.128 32.463-12.614l291.28-266.528 291.373 266.528c19.569 17.947 50.039 16.614 67.94-3.015 17.9-19.616 16.508-50.050-3.060-67.892l-119.369-109.294zM267.906 537.159v301.379c0.187 11.409 7.559 18.642 13.542 22.132 6.307 3.606 13.17 5.183 20.406 5.183l136.62 0.047c4.684 0 9.322-1.995 12.614-5.228 3.385-3.352 5.241-7.988 5.241-12.661v-145.027l136.11 0.047v144.979c0 4.672 1.9 9.31 5.24 12.661 3.34 3.234 7.929 5.182 12.66 5.182h136.528c7.327 0 14.145-1.577 20.451-5.182 6.028-3.49 13.357-10.724 13.588-22.132v-301.379l-256.546-234.656-256.453 234.656zM761.242 344.795v-170.15c0-10.296-8.394-18.712-18.782-18.712h-45.171c-10.342 0-18.642 8.417-18.642 18.712v94.606l-121.826-111.415c-18.411-16.857-46.467-16.857-64.878 0l-323.788 296.254c-19.569 17.842-20.916 48.276-3.015 67.892 9.46 10.342 22.446 15.629 35.476 15.629 11.64 0 23.234-4.128 32.463-12.614l291.28-266.528 291.373 266.528c19.569 17.947 50.039 16.614 67.94-3.015 17.9-19.616 16.508-50.050-3.060-67.892l-119.369-109.294z"
        :fill="color"
        p-id="3306"
      ></path>
    </svg>

    <svg
      v-if="type == 'info'"
      t="1636288064035"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="4773"
      :width="size"
    >
      <path
        d="M864 512C864 317.595768 706.40423 160 512 160 317.595768 160 160 317.595768 160 512 160 706.40423 317.595768 864 512 864 706.40423 864 864 706.40423 864 512ZM64 512C64 264.576432 264.576432 64 512 64 759.423565 64 960 264.576432 960 512 960 759.423565 759.423565 960 512 960 264.576432 960 64 759.423565 64 512Z"
        p-id="4774"
        :fill="color"
      ></path>
      <path
        d="M448 319.987679C448 284.64826 476.407348 256 512 256 547.346224 256 576 284.397866 576 319.987679L576 512.012321C576 547.35174 547.592652 576 512 576 476.653776 576 448 547.602134 448 512.012321L448 319.987679ZM448 704C448 668.653779 476.407348 640 512 640 547.346224 640 576 668.407347 576 704 576 739.346221 547.592652 768 512 768 476.653776 768 448 739.592653 448 704Z"
        p-id="4775"
        :fill="color"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "icon",
  props: {
    size: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: "#212121",
    },
    type: {
      type: [Number, String],
      default: "dot",
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  display: inline-block;
}
</style>